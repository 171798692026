<template>
    <CRow>
        <CCol sm="12">
            <ListForm :headerTitle="$t('menu.units')" :showSearchPanel="false">
                <template v-slot:headerLeft>
                    <button type="button" name="add-unit" class="btn btn-success" @click="onAddUnit">
                        {{ $t('pages.product.addUnit') }}
                    </button>
                </template>
                <template v-slot:searchBox>
                    <div></div>
                </template>

                <template v-slot:list>
                    <CDataTable :items="allUnits"
                                :fields="fields"
                                :noItemsView="{ noItems: $t('common.nodatafound') }"
                                hover
                                striped
                                border
                                small
                                fixed
                                :loading="isLoading"
                                :items-per-page-select="{
                                label : 'Số lượng',
                                values : [10,20,50,100]
                                }"
                                :items-per-page="itemsPerPage"
                                @pagination-change="onItemsPerPageChange"
                                pagination>
                        <template #footer v-if="allUnits != null && allUnits.length > 0">
                            <tfoot>
                                <tr>
                                    <td :colspan="8">
                                        <span>Tổng số</span><span style="padding-left:5px;color:crimson;font-weight:bold">{{allUnits == null ? 0: allUnits.length}}</span><span style="padding-left:5px">mẩu tin</span>
                                    </td>
                                </tr>
                            </tfoot>
                        </template>

                        <template #name="{item}">
                            <td>
                                {{item.name}}
                            </td>
                        </template>
                        <template #unit_detail_actions="{item, index}">
                            <td class="py-2">                                
                                <CButton color="primary"
                                         variant="outline"
                                         square
                                         size="sm"
                                         @click="onDeleteUnit(item, index)">
                                    {{ $t('common.remove') }}
                                </CButton>
                            </td>
                        </template>
                    </CDataTable>
                </template>
            </ListForm>
            <Confirmation ref="confirmation"></Confirmation>
        </CCol>
    </CRow>
</template>
<script>
    import i18n from '@/plugins/i18n'
    import Confirmation from '@/components/Confirmation.vue'
    import { mapGetters, mapState, mapActions } from 'vuex'
    import ListForm from '@/components/ListForm.vue'

    export default {
        name: 'UnitConversionList',
        data() {
            return {
               
                isLoading: false,
                itemsPerPage: 10,
                fields: [
                    { key: 'name', label: i18n.t('common.fromUnit') },
                    {
                        key: 'unit_detail_actions',
                        label: i18n.t('common.action'),
                        _style: 'width:12%',
                        sorter: false,
                        filter: false
                    }
                ]
            };
        },
        components: {
            Confirmation, ListForm
        },
        computed: {
            ...mapState('unit', ['allUnits']),
        },
        methods: {
            ...mapActions('unit', ['getAllUnits']),

            onItemsPerPageChange(item) {
                localStorage.setItem('unit-numItemsPerPage', item)
            },
            onAddUnit() {
                this.$router.push('/units/add');
            },
            onDeleteUnit(item) {
                this.$refs.confirmation.show(i18n.t('common.confirmDelete'), async () => {
                    var retVal = await this.$store.dispatch("unit/deleteUnit", item.id);
                    if (retVal == true) {
                        this.isLoading = true;
                        await this.getAllUnits();
                        this.isLoading = false;
                    }
                });
            },
        },
        async mounted() {
            let numItemsPerPage = parseInt(localStorage.getItem('unit-numItemsPerPage'));
            if (isNaN(numItemsPerPage))
                numItemsPerPage = 10;
            this.itemsPerPage = numItemsPerPage;

            this.isLoading = true;
            await this.getAllUnits();
            this.isLoading = false;
        }
    }
</script>